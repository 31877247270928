import React from "react";
import { createRoot } from "react-dom/client";

// Redux
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { appReducer } from "./app-reducer";
import { timelineReducer } from "./general-components/content-base/paragraphs/timeline/reducer-timeline";
import { kalenderMonthReducer } from "./general-components/content-base/paragraphs/kalender-monat/reducer-kalender-month";
import { adminAppAction } from "./app-actions";
import { i18nReducer } from "./i18n/i18n-reducer";
import { authenticationReducer } from "./intern/redux-authentication";

// GraphQL
import { restHost, graphQlEndpoint } from "./config";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import possibleTypes from "./../../public/data/possibleTypes.json";

import { BrowserRouter } from "react-router-dom";
import App from "./app";

const store = createStore(
    combineReducers({
      appStore: appReducer,
      timeLine: timelineReducer,
      kalenderMonth: kalenderMonthReducer,
      i18n: i18nReducer,
      auth: authenticationReducer,
    }),
    window.__PRELOADED_STATE__
  ),
  errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  client = new ApolloClient({
    link: from([
      errorLink,
      createHttpLink({
        uri: `${restHost}${graphQlEndpoint}`,
        credentials: "same-origin",
      }),
    ]),
    cache: new InMemoryCache({ possibleTypes }),
  });

// Allow the passed states to be garbage-collected
delete window.__PRELOADED_STATE__;

store.dispatch(adminAppAction(true));

window.onload = () => {
  /**
   * Full Page Slider on Frontpage.
   * @type {NodeListOf<Element>}
   */
  const adminApp = document.querySelector("#adminApp");

  if (adminApp.innerHTML === "") {
    const root = createRoot(adminApp);
    root.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <BrowserRouter forceRefresh={true}>
            <App />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    );
  }
};
